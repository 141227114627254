 export const carData = [
 {
   "year": 2020,
   "make": "Acura",
   "model": "ILX",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Acura",
   "model": "MDX",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Acura",
   "model": "MDX Sport Hybrid",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Acura",
   "model": "NSX",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Acura",
   "model": "RDX",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Acura",
   "model": "RLX",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Acura",
   "model": "RLX Sport Hybrid",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Acura",
   "model": "TLX",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Alfa Romeo",
   "model": "4C Spider",
   "body_styles": "[\"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "Alfa Romeo",
   "model": "Giulia",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Alfa Romeo",
   "model": "Stelvio",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "A3",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "A4",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "A5",
   "body_styles": "[\"Coupe\", \"Convertible\", \"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "A6",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "A6 allroad",
   "body_styles": "[\"Wagon\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "A7",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "Q3",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "Q5",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "Q7",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "Q8",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "R8",
   "body_styles": "[\"Coupe\", \"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "S4",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "S8",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "SQ5",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Audi",
   "model": "TT",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "2 Series",
   "body_styles": "[\"Coupe\", \"Sedan\", \"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "3 Series",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "4 Series",
   "body_styles": "[\"Sedan\", \"Coupe\", \"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "5 Series",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "7 Series",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "8 Series",
   "body_styles": "[\"Coupe\", \"Convertible\", \"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "M2",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "X1",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "X2",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "X3",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "X3 M",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "X4",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "X5",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "X6",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "X7",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "BMW",
   "model": "Z4",
   "body_styles": "[\"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "Buick",
   "model": "Enclave",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Buick",
   "model": "Encore",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Buick",
   "model": "Encore GX",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Buick",
   "model": "Envision",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Buick",
   "model": "Regal Sportback",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Buick",
   "model": "Regal TourX",
   "body_styles": "[\"Wagon\"]"
 },
 {
   "year": 2020,
   "make": "Cadillac",
   "model": "CT4",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Cadillac",
   "model": "CT5",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Cadillac",
   "model": "CT6",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Cadillac",
   "model": "CT6-V",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Cadillac",
   "model": "Escalade",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Cadillac",
   "model": "Escalade ESV",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Cadillac",
   "model": "XT4",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Cadillac",
   "model": "XT5",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Cadillac",
   "model": "XT6",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Blazer",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Bolt EV",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Camaro",
   "body_styles": "[\"Convertible\", \"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Colorado Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Colorado Extended Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Corvette",
   "body_styles": "[\"Coupe\", \"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Equinox",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Express 2500 Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Express 3500 Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Impala",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Malibu",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Silverado 1500 Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Silverado 1500 Double Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Silverado 1500 Regular Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Silverado 2500 HD Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Silverado 2500 HD Double Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Silverado 3500 HD Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Sonic",
   "body_styles": "[\"Sedan\", \"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Spark",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Suburban",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Tahoe",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Traverse",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Chevrolet",
   "model": "Trax",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Chrysler",
   "model": "300",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Chrysler",
   "model": "Pacifica",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Chrysler",
   "model": "Pacifica Hybrid",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Chrysler",
   "model": "Voyager",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Dodge",
   "model": "Challenger",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Dodge",
   "model": "Charger",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Dodge",
   "model": "Durango",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Dodge",
   "model": "Grand Caravan Passenger",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Dodge",
   "model": "Journey",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "FIAT",
   "model": "124 Spider",
   "body_styles": "[\"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "FIAT",
   "model": "500L",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "FIAT",
   "model": "500X",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "EcoSport",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Edge",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Escape",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Expedition",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Expedition MAX",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Explorer",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "F150 Regular Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "F150 Super Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "F150 SuperCrew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "F250 Super Duty Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "F250 Super Duty Regular Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "F250 Super Duty Super Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "F350 Super Duty Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "F350 Super Duty Super Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "F450 Super Duty Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Fusion",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Fusion Plug-in Hybrid",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Mustang",
   "body_styles": "[\"Coupe\", \"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Ranger SuperCab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Ranger SuperCrew",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Transit 250 Cargo Van",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Transit 350 Passenger Van",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Transit Connect Cargo Van",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Ford",
   "model": "Transit Connect Passenger Wagon",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Freightliner",
   "model": "Sprinter 1500 Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Freightliner",
   "model": "Sprinter 1500 Passenger",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Freightliner",
   "model": "Sprinter 2500 Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Freightliner",
   "model": "Sprinter 2500 Crew",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Freightliner",
   "model": "Sprinter 2500 Passenger",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Freightliner",
   "model": "Sprinter 3500 Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Freightliner",
   "model": "Sprinter 3500 Crew",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Freightliner",
   "model": "Sprinter 3500 XD Crew",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Freightliner",
   "model": "Sprinter 3500XD Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Freightliner",
   "model": "Sprinter 4500 Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Freightliner",
   "model": "Sprinter 4500 Crew",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Genesis",
   "model": "G70",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Genesis",
   "model": "G80",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Genesis",
   "model": "G90",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "GMC",
   "model": "Acadia",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "GMC",
   "model": "Canyon Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "GMC",
   "model": "Sierra 1500 Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "GMC",
   "model": "Sierra 1500 Double Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "GMC",
   "model": "Sierra 1500 Regular Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "GMC",
   "model": "Sierra 2500 HD Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "GMC",
   "model": "Sierra 3500 HD Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "GMC",
   "model": "Terrain",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "GMC",
   "model": "Yukon",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "GMC",
   "model": "Yukon XL",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "Accord",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "Accord Hybrid",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "Civic",
   "body_styles": "[\"Hatchback\", \"Coupe\", \"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "Civic Type R",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "Clarity Fuel Cell",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "Clarity Plug-in Hybrid",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "CR-V",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "CR-V Hybrid",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "Fit",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "HR-V",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "Insight",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "Odyssey",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "Passport",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "Pilot",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Honda",
   "model": "Ridgeline",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Accent",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Elantra",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Elantra GT",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Ioniq Electric",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Ioniq Hybrid",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Ioniq Plug-in Hybrid",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Kona",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Kona Electric",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "NEXO",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Palisade",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Santa Fe",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Sonata",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Sonata Hybrid",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Tucson",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Veloster",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Hyundai",
   "model": "Venue",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "INFINITI",
   "model": "Q50",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "INFINITI",
   "model": "Q60",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "INFINITI",
   "model": "QX50",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "INFINITI",
   "model": "QX60",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "INFINITI",
   "model": "QX80",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Jaguar",
   "model": "E-PACE",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Jaguar",
   "model": "F-PACE",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Jaguar",
   "model": "F-TYPE",
   "body_styles": "[\"Coupe\", \"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "Jaguar",
   "model": "I-PACE",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Jaguar",
   "model": "XE",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Jaguar",
   "model": "XF",
   "body_styles": "[\"Sedan\", \"Wagon\"]"
 },
 {
   "year": 2020,
   "make": "Jeep",
   "model": "Cherokee",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Jeep",
   "model": "Compass",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Jeep",
   "model": "Gladiator",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Jeep",
   "model": "Grand Cherokee",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Jeep",
   "model": "Renegade",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Jeep",
   "model": "Wrangler",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Jeep",
   "model": "Wrangler Unlimited",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Cadenza",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Forte",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "K900",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Niro",
   "body_styles": "[\"Wagon\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Niro EV",
   "body_styles": "[\"Wagon\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Niro Plug-in Hybrid",
   "body_styles": "[\"Wagon\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Optima",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Optima Hybrid",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Optima Plug-in Hybrid",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Rio",
   "body_styles": "[\"Sedan\", \"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Sedona",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Sorento",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Soul",
   "body_styles": "[\"Wagon\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Sportage",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Stinger",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Kia",
   "model": "Telluride",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Land Rover",
   "model": "Defender 110",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Land Rover",
   "model": "Defender 90",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Land Rover",
   "model": "Discovery",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Land Rover",
   "model": "Discovery Sport",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Land Rover",
   "model": "Range Rover",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Land Rover",
   "model": "Range Rover Evoque",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Land Rover",
   "model": "Range Rover Sport",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Land Rover",
   "model": "Range Rover Velar",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Lexus",
   "model": "ES",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Lexus",
   "model": "GS",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Lexus",
   "model": "GX",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Lexus",
   "model": "IS",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Lexus",
   "model": "LC",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Lexus",
   "model": "LS",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Lexus",
   "model": "LX",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Lexus",
   "model": "NX",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Lexus",
   "model": "RC",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Lexus",
   "model": "RX",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Lexus",
   "model": "UX",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Lincoln",
   "model": "Aviator",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Lincoln",
   "model": "Continental",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Lincoln",
   "model": "Corsair",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Lincoln",
   "model": "MKZ",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Lincoln",
   "model": "Nautilus",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Lincoln",
   "model": "Navigator",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Lincoln",
   "model": "Navigator L",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "MAZDA",
   "model": "CX-3",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "MAZDA",
   "model": "CX-30",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "MAZDA",
   "model": "CX-5",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "MAZDA",
   "model": "CX-9",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "MAZDA",
   "model": "MAZDA3",
   "body_styles": "[\"Sedan\", \"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "MAZDA",
   "model": "MAZDA6",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "MAZDA",
   "model": "MX-5 Miata",
   "body_styles": "[\"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "MAZDA",
   "model": "MX-5 Miata RF",
   "body_styles": "[\"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "A-Class",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "C-Class",
   "body_styles": "[\"Sedan\", \"Convertible\", \"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "CLA",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "CLS",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "E-Class",
   "body_styles": "[\"Sedan\", \"Coupe\", \"Wagon\", \"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "G-Class",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "GLA",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "GLB",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "GLC",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "GLE",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "GLS",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Mercedes-AMG A-Class",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Mercedes-AMG C-Class",
   "body_styles": "[\"Sedan\", \"Coupe\", \"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Mercedes-AMG CLS",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Mercedes-AMG GLC",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Mercedes-AMG GLC Coupe",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Metris WORKER Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Metris WORKER Passenger",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "S-Class",
   "body_styles": "[\"Sedan\", \"Coupe\", \"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "SLC",
   "body_styles": "[\"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Sprinter 1500 Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Sprinter 1500 Passenger",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Sprinter 2500 Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Sprinter 2500 Crew",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Sprinter 2500 Passenger",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Sprinter 3500 Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Sprinter 3500 Crew",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Sprinter 3500 XD Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Sprinter 3500 XD Crew",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Sprinter 4500 Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Mercedes-Benz",
   "model": "Sprinter 4500 Crew",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "MINI",
   "model": "Clubman",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "MINI",
   "model": "Countryman",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "MINI",
   "model": "Hardtop 2 Door",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "MINI",
   "model": "Hardtop 4 Door",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Mitsubishi",
   "model": "Eclipse Cross",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Mitsubishi",
   "model": "Mirage",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Mitsubishi",
   "model": "Mirage G4",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Mitsubishi",
   "model": "Outlander",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Mitsubishi",
   "model": "Outlander PHEV",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Mitsubishi",
   "model": "Outlander Sport",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "370Z",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Altima",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Armada",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Frontier Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Frontier King Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "GT-R",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Kicks",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "LEAF",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Maxima",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Murano",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "NV1500 Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "NV200",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "NV2500 HD Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "NV3500 HD Cargo",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "NV3500 HD Passenger",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Pathfinder",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Rogue",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Rogue Sport",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Sentra",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Titan Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Titan King Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "TITAN XD Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Nissan",
   "model": "Versa",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Porsche",
   "model": "718 Boxster",
   "body_styles": "[\"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "Porsche",
   "model": "718 Cayman",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Porsche",
   "model": "718 Spyder",
   "body_styles": "[\"Convertible\"]"
 },
 {
   "year": 2020,
   "make": "Porsche",
   "model": "911",
   "body_styles": "[\"Convertible\", \"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Porsche",
   "model": "Cayenne",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Porsche",
   "model": "Cayenne Coupe",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Porsche",
   "model": "Macan",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Porsche",
   "model": "Panamera",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Porsche",
   "model": "Taycan",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Ram",
   "model": "1500 Classic Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ram",
   "model": "1500 Classic Quad Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ram",
   "model": "1500 Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ram",
   "model": "1500 Quad Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ram",
   "model": "2500 Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ram",
   "model": "3500 Crew Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Ram",
   "model": "ProMaster Cargo Van",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Ram",
   "model": "ProMaster City",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Subaru",
   "model": "Ascent",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Subaru",
   "model": "BRZ",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Subaru",
   "model": "Crosstrek",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Subaru",
   "model": "Forester",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Subaru",
   "model": "Impreza",
   "body_styles": "[\"Wagon\", \"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Subaru",
   "model": "Legacy",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Subaru",
   "model": "Outback",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Subaru",
   "model": "WRX",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Tesla",
   "model": "Model 3",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Tesla",
   "model": "Model S",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Tesla",
   "model": "Model X",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Tesla",
   "model": "Model Y",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "4Runner",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "86",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Avalon",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Avalon Hybrid",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Camry",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Camry Hybrid",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "C-HR",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Corolla",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Corolla Hatchback",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Corolla Hybrid",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "GR Supra",
   "body_styles": "[\"Coupe\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Highlander",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Highlander Hybrid",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Land Cruiser",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Mirai",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Prius",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Prius Prime",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "RAV4",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "RAV4 Hybrid",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Sequoia",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Sienna",
   "body_styles": "[\"Van/Minivan\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Tacoma Access Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Tacoma Double Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Tundra CrewMax",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Tundra Double Cab",
   "body_styles": "[\"Pickup\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Yaris",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Toyota",
   "model": "Yaris Hatchback",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Volkswagen",
   "model": "Arteon",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Volkswagen",
   "model": "Atlas",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Volkswagen",
   "model": "Atlas Cross Sport",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Volkswagen",
   "model": "Golf",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Volkswagen",
   "model": "Golf GTI",
   "body_styles": "[\"Hatchback\"]"
 },
 {
   "year": 2020,
   "make": "Volkswagen",
   "model": "Jetta",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Volkswagen",
   "model": "Jetta GLI",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Volkswagen",
   "model": "Passat",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Volkswagen",
   "model": "Tiguan",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Volvo",
   "model": "S60",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Volvo",
   "model": "S90",
   "body_styles": "[\"Sedan\"]"
 },
 {
   "year": 2020,
   "make": "Volvo",
   "model": "V60",
   "body_styles": "[\"Wagon\"]"
 },
 {
   "year": 2020,
   "make": "Volvo",
   "model": "V90",
   "body_styles": "[\"Wagon\"]"
 },
 {
   "year": 2020,
   "make": "Volvo",
   "model": "XC40",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Volvo",
   "model": "XC60",
   "body_styles": "[\"SUV\"]"
 },
 {
   "year": 2020,
   "make": "Volvo",
   "model": "XC90",
   "body_styles": "[\"SUV\"]"
 }
]