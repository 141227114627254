import React from "react";

export const Card = ({src}) => {
  return (
    <div>
      <iframe
        width="400"
        height="300"
        src= {src}
        title="YouTube video player"
        frameborder="0"
        
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        // referrerpolicy="strict-origin-when-cross-origin"
        
        allowFullScreen
      ></iframe>
    </div>
  );
};
