import React, { useEffect, useState } from "react";
import { Card } from "../../explore/Card";
import { generateLink } from "../../../utility/utils";
import Pagination from "../../Common/Pagination";
const Explore = () => {
  const embedVideos = [
    {
      id: "XWGacAS3vCM?si=Wbvu53bMgTwXnVAF",
    },
    {
      id: "eJ_rLDygIE4?si=mr51oSFMH-FYFvEC",
    },
    {
      id: "RbOiL8n9sLU?si=6sQowsvoKJSF_m-U",
    },
    {
      id: "LY9nvbd6jrU?si=jLKb3SFmKUOzIxyW",
    },
  ];

  const [links, setLinks] = useState([]);
  const totalPages = Math.ceil(embedVideos.length / 6);
  const [pagination, setPagination] = useState({
    limit: 6,
    page: 1,
    totalPages: totalPages,
    pageIndex: 0,
  });

  useEffect(() => {
    videoToBeShow();
  }, [pagination.page]);

  function videoToBeShow() {
    // Logic to slice and display videos based on pagination.pageIndex
    const startIndex = (pagination.page - 1) * pagination.limit;
    const endIndex = startIndex + pagination.limit;

    let videoToBeShow = embedVideos.slice(startIndex, endIndex);

    setLinks(videoToBeShow);
  }

  const handlePageChange = (newPageIndex) => {
    setPagination((prev) => ({ ...prev, pageIndex: newPageIndex }));
    setPagination((prev) => ({ ...prev, page: newPageIndex + 1 }));
  };

  return (
    <div className="h-[calc(100vh-50px)] overflow-y-auto">
      <div className="h-[calc(76vh)] overflow-y-auto">
        <div className="flex px-5 py-2 h-[80px] justify-between items-center border-b-[2px] border-lightBorderColor">
          <p className="text-black text-2xl">Explore & learn </p>
        </div>
        <div className="flex flex-wrap gap-4 justify-center p-2">
          {links?.map((ele) => {
            return <Card src={generateLink(ele?.id)} />;
          })}
        </div>
      </div>

      <div className="w-full mt-auto bottom-0 sticky bg-white p-4">
        {/* Reusable Pagination Component */}
        <Pagination
          currentPageIndex={pagination.pageIndex}
          totalPages={pagination.totalPages}
          handlePageChange={handlePageChange}
          canPreviousPage={pagination.page > 1 ? true : false}
          canNextPage={pagination.page < pagination.totalPages ? true : false}
        />
      </div>
    </div>
  );
};

export default Explore;
