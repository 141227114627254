import { calenderIcon, statIcon } from "../../assets";
import axiosInstance from "../../utility/axiosInstance";
import { DoughnutChart } from "./BarChart";
import { useEffect, useState } from "react";
import { IoPieChart } from "react-icons/io5";


export const UsedCarPieChart = () => {
  const [soldCarsCount, setSoldCarsCount] = useState(5);
  const [rejectedDealsCount, setRejectedDealsCount] = useState(1);
  const [data, setData] = useState([]);

  const [timePeriod, setTimePeriod] = useState("week");

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `usedCars/bookings/stats?timeframe=${timePeriod}`
      );

      setData(response?.data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, [timePeriod]);

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  useEffect(() => {
    if (data) {
      const counts = data?.reduce(
        (acc, item) => {
          if (item?.status == "rejected") {
            acc.rejected += item.count;
          }
          if (item.status == "dealClosed") {
            acc.sold += item.count;
          }
          return acc;
        },
        { sold: 0, rejected: 0 }
      );

      setSoldCarsCount(counts.sold);
      setRejectedDealsCount(counts.rejected);
    }
  }, [data]);

  return (
    <div className="">
      {/* <section className="">
        <div className="relative h-auto">
          <DoughnutChart
            labels={["Sold Cars", "Rejected Deals"]}
            data={[soldCarsCount, rejectedDealsCount]}
            backgroundColor={["#E3E3E3", "#252525"]}
            offset={[0, 80]}
          />

          <div className="absolute bottom-[43%] left-[24%]">
            <div className="p-2 font-semibold text-2xl flex flex-col justify-center items-center m-auto">
              <div>{soldCarsCount + rejectedDealsCount}</div>
              <div className="text-lg font-medium">Used Cars</div>
            </div>
          </div>
        </div>
      </section> */}

      <div className="h-[400px] shadow-xl rounded-xl pt-2 px-2">
        <div className="w-[100%]">
          <div className="flex justify-between ">
            <div className="flex items-center gap-3 text-2xl font-medium">
              <div className="bg-[#252525] p-1.5 rounded-md">
              <IoPieChart size={30} color="white"/>
              </div>
               Used Cars
              {/* <img src={statIcon} alt="" /> Used Cars */}
            </div>
            <div className="flex flex-col gap-3 relative z-40">
              <select
                className="border-borderColor cursor-pointer rounded-lg pl-8 border-[2px] focus:outline-none py-2 px-3"
                name="timePeriod"
                id="timePeriod"
                onChange={handleTimePeriodChange}
              >
                <option value="week">This Week</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </select>
              <div className="pointer-events-none absolute left-0 top-[7px] flex items-center px-2 text-gray-700">
                <img className="h-[20px]" src={calenderIcon} alt="" />
              </div>
            </div>
          </div>
          <div className="flex -mt-[90px]">
            <span className="m-auto">
              <div className="relative top-0 bottom-12">
                <div className="">
                  <DoughnutChart
                    labels={["Sold Cars", "Rejected Deals"]}
                    data={[soldCarsCount, rejectedDealsCount]}
                    backgroundColor={["#252525","#E3E3E3", ]}
                    offset={[0, 80]}
                  />
                </div>

                <div className="absolute bottom-[43%] left-[24%]">
                  <div className="p-2 font-semibold text-2xl flex flex-col justify-center items-center m-auto">
                    <div>{soldCarsCount + rejectedDealsCount}</div>
                    <div className="text-lg font-medium text-[#C8C8C8]">Used Cars</div>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
