import React, { useContext, useEffect } from "react";
import WorkTimeSettingComponent from "../../Common/settingComponet/WorkTimeSettingComponent";
import { UsedCarSettingContext } from "./UsedCarSettingIndex";
import TimeSlotComponent from "../../Common/settingComponet/TimeSlotComponent";
import { useSelector } from "react-redux";

export default function TimeView() {
  const {
    compareMonthAndYear,
    selectedCar,
    workingDayTime,
    selectedCars,
    currentMonth,
    handleMonthDateUpdate,
    workingTime,
    setWorkingDayTime,
    compareTwoDate,
    dateValue,
    usedCars,
    handleSingleUpdate,
  } = useContext(UsedCarSettingContext);
  const { usedCarSettingInfo } = useSelector((state) => state.usedCars);
  useEffect(() => {
    try {
      if (Object.keys(workingTime).length !== 0) {
        let currentTime = workingTime.start;
        let endTime = workingTime.end;
        let startDate = new Date();
        let endDate = new Date();
        let [startHour, startMinute] = currentTime.split(":");
        let [endHour, endMinute] = endTime.split(":");

        // Set start and end times
        startDate.setHours(startHour, startMinute, 0, 0);

        let [endStartHour, endStartMinute] = endTime.split(":");
        endDate.setHours(endStartHour, endStartMinute - 15, 0, 0);

        let min = 0;
        const allTime = [];
        let dateData = null;

        // Get the dateData for selected car if available
        if (selectedCar && usedCarSettingInfo?.length > 0) {
          const exist = usedCarSettingInfo.find(
            ({ car }) => car === selectedCar
          );
          if (exist) {
            const tempMonth = exist?.usedCarMonthSetting.find(
              ({ monthYearDate }) =>
                compareMonthAndYear(monthYearDate, currentMonth)
            );

            if (tempMonth) {
              dateData = tempMonth?.usedCarDateSettings?.find(({ date }) =>
                compareTwoDate(date, dateValue)
              );
            }
          }
        }

        // Iterate over the selected cars if present
        if (selectedCars.length > 0 && usedCarSettingInfo?.length > 0) {
          // const exist = usedCarSettingInfo[0]; // Assuming you want to fetch data for the first used car

          const exist2 = usedCarSettingInfo?.filter((ele) => {
            if (selectedCars.includes(ele.car)) {
              return ele;
            }
          });

          if (exist2) {
            const tempMonth = exist2?.map((ele) => {
              const tempMonth = ele?.usedCarMonthSetting.find(
                ({ monthYearDate }) =>
                  compareMonthAndYear(monthYearDate, currentMonth)
              );
              return { tempMonth, car: ele.car };
            });

            if (tempMonth) {
              dateData = tempMonth?.map((ele) => {
                let car = ele.car;
                const exist = ele?.tempMonth?.usedCarDateSettings?.find(
                  // add th
                  ({ date }) => compareTwoDate(date, dateValue)
                );
                // add the cars inside the timeslots array

                return { exist, car: ele.car };
              });

              // dateData = tempMonth?.usedCarDateSettings?.find(({ date }) =>
              //   compareTwoDate(date, dateValue)
              // );
            }
          }
        }

        // Loop to create time slots based on the working times
        while (startDate < endDate) {
          startDate.setMinutes(startDate.getMinutes() + 15); // Increment by 15 minutes
          const tempTime = startDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });

          let status = {
            status: false,

            carId: null,
          };

          if (dateData) {
            // two timeSlots in dataData array merge them together

            const mergedTimeSlots = dateData?.reduce((acc, ele) => {
              const carId = ele.car; // Assume `carId` is part of the object `ele`
              const slotsWithCarId = ele.exist.timeSlots.map((slot) => ({
                slot,
                carId,
              }));

              // Merge existing slots and avoid duplicates by checking both slot and carId
              const mergedSlots = [
                ...acc,
                ...slotsWithCarId.filter(
                  (newSlot) =>
                    !acc.some(
                      (existingSlot) =>
                        existingSlot.slot === newSlot.slot &&
                        existingSlot.carId === newSlot.carId
                    )
                ),
              ];

              return mergedSlots;
            }, []);
            // Check if the current time slot is within the working time

            const exist = mergedTimeSlots?.find(
              (slot) => slot.slot.time === tempTime
            );
            // console.log(exist, "eixtslots");
            // supoose two exists is there then how will you define the time
            // const exist  = dateData?.map((ele)=>{
            //   const exist = ele?.timeSlots?.find(
            //     ({ time }) => time === tempTime
            //   );
            //   return exist;
            // })

            status.status = exist ? exist.slot.status : false;
            status.carId = exist ? exist.carId : null; // Assume `carId` is part of the object `exist`
          }

          allTime.push({
            time: tempTime,
            status: status,
          });
        }
        setWorkingDayTime(allTime);
      }
    } catch (error) {
      console.log(error);
    }
  }, [setWorkingDayTime, workingTime, dateValue, usedCarSettingInfo]);

  return (
    <div className="flex flex-col gap-5 no-scrollbar">
      <WorkTimeSettingComponent
        SettingContextData={useContext(UsedCarSettingContext)}
        handleMonthDateUpdate={async () => {
          await handleMonthDateUpdate({
            usedCars: selectedCars,
            monthYearDate: currentMonth,
            workingTime,
          });
        }}
      />
      <TimeSlotComponent
        SettingContextData={useContext(UsedCarSettingContext)}
        handleSingleUpdate={async () =>
          await handleSingleUpdate({
            usedCars: selectedCars,
            workingDayTime,
            currentDate: dateValue,
            monthYearDate: currentMonth,
          })
        }
        handleMonthDateUpdate={async () =>
          await handleMonthDateUpdate({
            usedCars: selectedCars,
            monthYearDate: currentMonth,
            workingDayTime,
          })
        }
        selectedCars={selectedCars}
        usedCarSettingInfo={usedCarSettingInfo}
        usedCars={usedCars}
        type="usedCarsSettings"
      />
    </div>
  );
}
