import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";

const CustomTooltip = ({ text, tooltipText }) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);

  useEffect(() => {
    const { children } = tooltipText?.props;

    const array = children?.map((ele) => ele.props.children);

    const strModification = array?.map((item) => item.join(""));

    setData(strModification);
  }, [tooltipText]);

  const handleMouseEnter = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top + window.scrollY - 10, // Position above the button
        left: rect.left + window.scrollX + rect.width / 2, // Centered horizontally
      });
    }
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <div>
      <button
        ref={buttonRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {text}
      </button>

      {/* Tooltip */}
      {visible &&
        createPortal(
          <div
            style={{
              position: "absolute",
              top: `${position.top}px`,
              left: `${position.left}px`,
              transform: "translate(-50%, -100%)",
              zIndex: 50,
            }}
            className="rounded-lg bg-black py-1 px-3 text-sm text-white shadow-lg"
          >
            {data?.map((ele, ind) => (
              <p className="min-w-[200px]" key={ind}>
                {ele}
              </p>
            ))}
          </div>,
          document.body
        )}
    </div>
  );
};

export default CustomTooltip;
