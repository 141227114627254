import React, { useContext, useEffect, useRef, useState } from "react";
import {
  HeaderText,
  ServiceSettingContext,
} from "../../Service/ServiceSetting/ServiceSettingIndex";
import PrimaryButton from "../../utils/buttons/primaryButton";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { Tooltip } from "flowbite-react";
import { UsedCarSettingContext } from "../../UsedCars/usedCarSetting/UsedCarSettingIndex";
import CustomTooltip from "../CustomTooltip";

const TimeSlotComponent = ({
  SettingContextData,
  handleMonthDateUpdate,
  handleSingleUpdate,
  selectedCars,
  usedCarSettingInfo,
  type = "serviceSettings",
}) => {
  const {
    workingDayTime,
    setAvailableWorkingDays,
    dateValue,
    availableWorkingDays,
    setWorkingDayTime,
    setTempTimeSave,
    compareTwoDate,
    CustomDatePicker,
  } = SettingContextData;
  const { usedCars } = useContext(UsedCarSettingContext);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterSelectCars, setFilterSelectCars] = useState([]);

  const getCarName = (id) => {
    const car = filterSelectCars.find((car) => car?._id == id);
    if (car) {
      return car.name;
    }
    return "";
  };

  const fineCarsBasedOnWorkingTime = (
    usedCarSettingInfo,
    workingDayTime,
    selectedCars,
    getTime
  ) => {
    const fineCars = usedCarSettingInfo?.filter((car) => {
      if (selectedCars?.includes(car.car)) {
        return car;
      }
    });
    const filteredData = fineCars?.map((car) => {
      const monthSettings = car?.usedCarMonthSetting?.map((month) =>
        month?.usedCarDateSettings
          ?.filter(({ date }) => {
            const carDate = new Date(date);
            const filterDate = new Date(dateValue);
            return (
              carDate.getFullYear() === filterDate.getFullYear() &&
              carDate.getMonth() === filterDate.getMonth() &&
              carDate.getDate() === filterDate.getDate()
            );
          })
          .map(({ date, timeSlots }) => ({ date, timeSlots }))
      );

      return {
        car: car.car,
        data: monthSettings[0],
      };
    });

    const findByWorkingTime = filteredData?.map((ele) => {
      const car = ele.car;
      const timeSlotsFilter = ele.data[0]?.timeSlots?.filter((time) => {
        return time.time.includes(getTime);
      });
      return {
        timeSlotsFilter: timeSlotsFilter?.map((status) => status.status),
        car,
      };
    });

    const res = findByWorkingTime?.map((ele) => {
      return {
        car: ele.car,
        status: ele.timeSlotsFilter?.includes(true)
          ? "Available"
          : "Not Available",
      };
    });

    return res;
  };

  useEffect(() => {
    setData(workingDayTime);
    if (usedCars && type == "usedCarsSettings") {
      const filterdUsedCars = usedCars?.map((ele) => {
        if (selectedCars.includes(ele._id)) {
          return {
            _id: ele._id,
            name: ele.overview.carName,
          };
        }
      });

      setFilterSelectCars(filterdUsedCars);
    }
  }, [workingDayTime, setWorkingDayTime]);

  console.log(data, "data is here");

  return (
    <div className="flex flex-col flex-wrap gap-6 border border-black px-10 py-10 rounded-lg no-scrollbar">
      <div className="flex flex-col gap-9  ">
        <div className="flex flex-col  gap-6">
          <HeaderText text={"Time Setting"} />
          {CustomDatePicker()}
        </div>
        <div
          className="bg-[#e5e7eb] p-[15px] rounded-[12px] border-none shadow-md w-full flex gap-8 flex-wrap overflow-y-auto  
       max-h-[25.2rem]"
        >
          {data?.map(({ time, status }, i) => {
            // Normalize the status field to handle both scenarios
            const isStatusActive =
              typeof status === "boolean" ? status : status?.status ?? false;

            return (
              <span
                key={i}
                onClick={() => {
                  const find = availableWorkingDays.find(({ date }) =>
                    compareTwoDate(date, dateValue)
                  );

                  if (find) {
                    find.timeSlots = find.timeSlots.map((item) => {
                      if (item.time === time) {
                        if (typeof item.status === "boolean") {
                          item.status = !item.status; // For service scenario
                        } else if (
                          typeof item.status === "object" &&
                          item.status
                        ) {
                          item.status.status = !item.status.status; // For used cars scenario
                        }
                      }
                      return item;
                    });

                    setAvailableWorkingDays((state) =>
                      state.map((item) =>
                        item.date === find.date ? find : item
                      )
                    );
                    setWorkingDayTime([...find.timeSlots]);

                    setTempTimeSave((prevState) => {
                      const existIndex = prevState.findIndex(({ date }) =>
                        compareTwoDate(date, dateValue)
                      );
                      if (existIndex >= 0) {
                        const updatedState = [...prevState];
                        updatedState[existIndex] = {
                          ...updatedState[existIndex],
                          timeSlots: find.timeSlots,
                        };
                        return updatedState;
                      }

                      return [
                        ...prevState,
                        { ...find, date: dateValue, timeSlots: find.timeSlots },
                      ];
                    });
                  }
                }}
                className={`color-[#111827] cursor-pointer text-lg p-[7px] text-center rounded-[8px]`}
                style={{
                  backgroundColor: isStatusActive
                    ? "rgb(84 207 84)"
                    : "#d1d5db",
                }}
              >
                {isStatusActive && type == "usedCarsSettings" ? (
                  <CustomTooltip
                    tooltipText={
                      <>
                        {fineCarsBasedOnWorkingTime(
                          usedCarSettingInfo,
                          workingDayTime,
                          selectedCars,
                          time
                        )?.map((ele) => {
                          return (
                            <p className="" key={ele?.car}>
                              {getCarName(ele?.car)}: {ele?.status}
                            </p>
                          );
                        })}
                      </>
                    }
                    text={time}
                  />
                ) : (
                  <p>{time}</p>
                )}
                {/* {selectedCars?.length > 4 && time} */}
              </span>
            );
          })}
        </div>
      </div>

      <div className="flex justify-start gap-4">
        <PrimaryButton
          width="130px"
          title={"Update Time"}
          // onClick={handleUpdate}
          onClick={async () => {
            setIsLoading(true);
            try {
              await handleSingleUpdate();
            } catch (err) {
              console.log(err);
            } finally {
              setIsLoading(false);
            }
          }}
          loading={isLoading}
        />

        <PrimaryButton
          width="120px"
          title={"Update All"}
          onClick={async () => {
            setIsLoading(true);
            try {
              await handleMonthDateUpdate();
            } catch (err) {
              console.log(err);
            } finally {
              setIsLoading(false);
            }
          }}
          // onClick={handleUpdate}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default TimeSlotComponent;
