import { createSlice } from "@reduxjs/toolkit";
import {
  addUsedCars,
  deleteUsedCars,
  fetchUsedCars,
  updateUsedCars,
} from "./usedCarsThunk";

const usedCarsSlice = createSlice({
  name: "usedCarsSlice",
  initialState: {
    list: [],
    loading: false,
    usedCarRequest: false,
    meta: null,
    usedCarSettingInfo: [],
    lastUpdatedSettings: null,
  },
  reducers: {
    setUsedCars: (state, action) => {
      state.list = action.payload;
      state.meta = action.meta;
    },
    setUsedCarSettingInfo: (state, action) => {
      state.usedCarSettingInfo = action.payload;
    },
    setLastUpdatedSettings: (state, action) => {
      state.lastUpdatedSettings = action.payload;
    },
    setUsedCarsRequest: (state, action) => {
      state.usedCarRequest = action.payload;
    },
    addUsedCarsSuccess: (state, action) => {
      state.list.push(action.payload);
    },
    deleteUsedCarsSuccess: (state, action) => {
      state.list = state.list.filter((part) => part.id !== action.payload);
    },
    updateUsedCarsSuccess: (state, action) => {
      const index = state.list.findIndex(
        (part) => part.id === action.payload.id
      );
      state.list[index] = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUsedCars.fulfilled, (state, action) => {
        // state.list.push(action?.payload?.data);
        state.meta = action?.payload?.meta;
        // state.usedCarRequest = false;
      })
      .addCase(fetchUsedCars.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsedCars.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data;
        state.meta = action?.payload?.meta;
      })
      .addCase(fetchUsedCars.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateUsedCars.fulfilled, (state, action) => {
        const index = state?.list?.findIndex(
          (part) => part?._id === action.payload?.data?._id
        );
        state.list[index] = action?.payload?.data;
        state.meta = action?.payload?.meta;
      })
      .addCase(updateUsedCars.rejected, (state) => {
        console.log("Error updating used car");
      })
      .addCase(deleteUsedCars.fulfilled, (state, action) => {
        state.list = state?.list?.filter(
          (part) => part?._id !== action?.payload?.data?._id
        );
        state.meta = action?.payload?.meta;
      });
  },
});

export const {
  setUsedCars,
  addUsedCarsSuccess,
  deleteUsedCarsSuccess,
  updateUsedCarsSuccess,
  setLoading,
  setUsedCarsRequest,
  setUsedCarSettingInfo,
  setLastUpdatedSettings
} = usedCarsSlice.actions;

export default usedCarsSlice.reducer;
