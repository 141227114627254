export const termsData = [
  {
    title: "CONTRACTUAL RELATIONSHIP",
    content: [
      "______________, incorporated under the Laws of Canada, having its registered office at _________________________________ operating under the trademarked name “______________”, provides holistic car services including maintenance, repair, etc. The use and access of our website www.______________.in (“Website”), and our mobile applications “______________ - Car Services, Battery, Spare parts & Tyre” (“Applications”), or any products or services in connection with the Application or Website (“Services”) shall be governed by these terms and conditions (“Terms”). The Website and Applications are together called the “Platform”.",
      "These terms constitute a legally binding and enforceable agreement between ______________ (“Company/we/us/our”) and its Users (“User/you/your”) and govern your use of the Platform to browse and/or avail the Services displayed by us on the Platform. By accessing and using our Services, you agree to be bound by these terms, establishing a contractual relationship between you and the Company. In case you do not agree to the terms, you may not use or access or stop your use or access of our Services. These Terms expressly supersede prior written agreements with you. The Company requests the User to carefully go through these terms & conditions (“Terms and Conditions” or “Terms”) prior to accessing the Platform or availing Services (as defined below) using the Platform.",
      "The Company reserves the right, in its sole discretion, to change, modify or amend the Terms for complying with the legal or regulatory framework and for other legitimate business purposes, at any time, and the Company will post the amended Terms at the domain of www.______________.in/terms. It is your responsibility to review the Terms for any changes and you are encouraged to check the Terms frequently. The Company shall not be under an obligation to notify Users of any changes to the Terms and Conditions. If you continue to use the Platform, Content (as defined below) and/or Services after the updated Terms and Conditions have been published, it shall be deemed that you have read and understood and accept the updated Terms and Conditions. Any reference to Terms and Conditions, made herein shall refer to the latest version of the Terms and Conditions.",
      "No information provided on the Platform shall be considered a substitute for your independent investigation. These Terms constitute an electronic record in accordance with the Personal Information Protection and Electronic Documents Act (PIPEDA) and other applicable Canadian federal and provincial legislation. These Terms are generated by a computer system and do not require any physical or electronic signature for validity. ",
    ],
  },
  {
    title: "DEFINITIONS",
    content: [
      "“Content” will include (but is not limited to) reviews, images, photos, audio, video, location data, nearby places, and all other forms of information or data. Your Content or User Content means Content that you upload, share or transmit, through or in connection with the Services, such as likes, ratings, reviews, images, photos, messages, profile information, and any other materials that you publicly display or displayed in your account profile. ______________ Content means Content that the Company creates and makes available in connection with the Services including, but not limited to, visual interfaces, interactive features, graphics, design, compilation, computer code, products, software, aggregate ratings, reports and other usage-related data in connection with activities associated with your account and all other elements and components of the Services excluding Your Content.",
      "Estimated Cost” will include the service provider’s approximation of what the Service is likely to cost. The purpose of cost estimation is to predict the quantity, cost, and price of the resources required to complete a job.",
      "“Membership Plan(s)” means subscription or other kinds of membership offers provided by the Company to Users from time to time through online or offline media. The terms, conditions and prices of such plans shall be communicated through the applicable media at the time of issuance of such offers.     ",
      " “Service(s)” means services provided through the Platform, as described in further detail under Clause 3 below. The Services provided through the Platform may change from time to time, at the sole discretion of the Company.",
      "“User” or “You” shall mean any individual who owns a car (primarily out of the manufacture warranty period) in urban centres especially metros, who is desirous of availing Services on the Platform as and who are registered on the Platform for this purpose.",
    ],
  },
  {
    title: "SERVICES",
    content: [
      "The Services constitute a technology platform that enables Users of the Company’s Platform, provided as part of the Services, to arrange and schedule repair or maintenance services for their car with independent third-party providers and/or purchase spare parts. Once you submit a request, we may provide you with a list of possible service providers, including their experience information.",
      "The Company also offers towing services to get your vehicle out of the situation and back on the road, available on a chargeable basis. These services shall be provided by a third-party service provider, who is bound by these terms and conditions and must adhere to the same standards and obligations as outlined in this agreement. The Company shall not be liable for any damage or loss caused while towing the vehicle; You agree that the entire responsibility for such damages or losses shall rest with the third-party service provider.",
      "You acknowledge that the Company does not provide any repair, maintenance, or spare part sales directly and that all such services, including the sale of spare parts, are provided by independent third-party contractors who are not employed by the Company. The Company does not provide any warranty or guarantee (express or implied) regarding the quality, availability, or condition of the spare parts or services provided by these third parties.",
    ],
  },
  {
    title: "ELIGIBILITY",
    content: [
      "You hereby represent and warrant that you are eighteen (18) years of age or above and are fully able and competent to understand and agree to the terms, conditions, obligations, affirmations, representations, and warranties set forth in these terms, and to abide by and comply with the Terms stated therein.",
      "You are in compliance with all laws and regulations in the country in which you live when you access and use the Services. You agree to use the Services only in compliance with these Terms and applicable laws, and in a manner that does not violate our legal rights or those of any third parties.",
      "If you will be using the Services on behalf of an organization, you agree to these Terms on behalf of that organization and you represent that you have the authority to act on behalf of your organization.",
    ],
  },
  {
    title: "USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS",
    content: [
      "All comments, feedback, reviews, suggestions, ideas, and other submissions disclosed, submitted or offered to the Company on or by the Platform or otherwise disclosed, submitted or offered in connection with your use of the Platform (collectively, the 'Comments') shall be and remain Company’s property. Such disclosure, submission or offer of any Comments shall constitute an assignment to the Company of all worldwide rights, titles and interests and shall not be limited in any way in its use, commercial or otherwise, of any Comments.",
      "The Company shall be under no obligation (1) to maintain any Comments in confidence; (2) to pay to User any compensation for any Comments; or (3) to respond to any User Comments.",
      "You agree that no Comments submitted by you to the Platform will violate any right of any third party, including copyright, trademark, privacy or other personal or proprietary rights(s). You further agree that no Comments submitted by you to the Platform will be or contain libelous or otherwise unlawful, abusive or obscene material. You are and shall remain solely responsible for the content of any Comments you make.",
    ],
  },

  {
    title: "USER DATA",
    content: [
      "You represent and warrant that any information provided by you in connection with your use of the Platform is and shall remain true, accurate and complete and that you will maintain and update such information regularly. You agree that if any information you provide to us is false, inaccurate, obsolete or incomplete, we may terminate your use of and access to the Platform.",
      "You agree that the Company, may, in accordance with its Privacy Policy, collect and use your information, technical data, and related information for the validation of the documents submitted. The Company may use information and data pertaining to your use of the Services for analytics, trends identification, and purposes of statistics to further enhance the effectiveness and efficiency of the Platform and transfer the same to its group companies and service providers in furtherance of your access to these Services. You provide your consent to such use and sharing of your information.",
      "Subject to applicable laws, the Company may be directed by law enforcement agencies or the government and related bodies to disclose data in relation to Users in connection with criminal proceedings. You understand and agree that in such instances, the Company shall have the right to share such data with relevant agencies or bodies.",
    ],
  },
  {
    title: "DOCUMINTELLECTUAL PROPERTY RIGHTSENTS",
    content: [
      "The Platform is designed, updated and maintained independently by the Company. When you access the Platform, you do so under a license granted from us. All rights, titled and interest in text, graphics, audio, design and other works on the Platform together with all the intellectual property related thereto including inter alia the domain name, copyrights, the look and feel of the Platform, the trade name and trademarks, patents and designs are the sole property of the Company or its licensors. All third-party trademarks and brand names that appear on the Platform are the property of their respective owners. Content on the Platform is for personal use only, unless permitted otherwise. Any alteration of the material or use of the material contained in the Platform for any commercial purpose is a violation of the intellectual property of the Company and/or its affiliates or associates or of its third-party information providers. We reserve the right to suspend or cancel the service at any time if a computer virus, bug, or other technical problem corrupts the security or proper administration of the service.",
      "You acknowledge and agree that through your use of the Platform or by availing any Services through or at the Platform, no right (empress or implied) is granted to you in respect of such Content. The Company reserves the right to change or modify the Content from time to time at its sole discretion.",
    ],
  },
  {
    title: "LICENSE",
    content: [
      "Subject to your compliance with these Terms, the Company grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable licence to (i) access and use the Applications on your personal device solely in connection with your use of the Services; and (ii) access and use any content, information, and related materials that may be made available through the Services, in each case, solely in connection with your use of the Services. The Company and its licensors reserve all rights not expressly granted herein.",
      "You will not use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Platform and Services, except as expressly permitted in the Terms and Conditions. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by the Company or its licensors, except for the licenses and rights expressly granted in the Terms and Conditions. The Platform and the intellectual property rights vested therein are owned by the Company.",
    ],
  },
  {
    title: "LIMITATION OF LIABILITY",
    content: [
      "The Company does not warrant that the results that are obtained from the use of the Service will be accurate or reliable. You agree that except as expressly stated by the Company, the Services and all the products provided/delivered to you through the Service are provided 'as is' and 'as available for your use, and are without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties. While we endeavour to ensure your access and use of the Platform is safe and available, we cannot and do not represent and warrant that the Platform or its server will be error-free, uninterrupted, free from unauthorized access, and/or otherwise meet your requirements.",
      "In no case are our directors, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors, liable for indirect, incidental, special, exemplary, punitive or consequential damages, including lost profits, lost data, personal injury or property damage related to, in connection with, or otherwise resulting from any use of the Services, even if the Company has been advised of the possibility of such damages.",
      "The Company operates solely as an online platform that facilitates the purchase of car spare parts from third-party sellers. The Company neither manufactures nor holds any inventory of the spare parts listed on the platform. All transactions for the purchase of spare parts are directly between the User and the third-party seller. The Company does not provide any form of guarantee, warranty (express or implied), or assurance regarding the quality, fitness for purpose, or condition of the spare parts. Any issues, including but not limited to defects, malfunctions, or inaccuracies in the description of the spare parts, shall be addressed directly with the third-party seller. The Company shall not be held liable for any claims, damages, or losses arising from the purchase or use of the spare parts obtained through the platform.",
      "The Company shall not be liable for any damages, liability or losses arising out of your use of or reliance on the Services or your inability to access or use the Services; or any transaction or relationship between you and any third-party provider or any delay or failure in performance resulting from causes beyond Company’s reasonable control.",
    ],
  },
  {
    title: " EXEMPTIONS TO LIABILITY OF COMPANY",
    content: [
      "User further agrees and confirms that the Company shall not be responsible, in any manner whatsoever, for any delay/unavailability of Services or failure to meet its obligations under the Terms and Conditions, which may be caused, directly or indirectly, due to: your failure to cooperate; your unavailability and/or unresponsiveness; your failure to provide accurate and complete information; your failure to provide or facilitate the submission of User materials in a timely manner; or any event beyond the Company’s reasonable control",
      "User further agrees that the Company shall not be liable to provide assistance and cooperation in the event of theft or accident of the vehicles while in the premises of the service partners. The Company may provide requisite assistance in respect of any insurance claims, reporting to police and/or any other formal steps as may be required but shall not be liable to compensate the User for its loss unless the same is caused due to an act solely attributable to the Company or due to gross negligence of Company (and not the service partner).",
    ],
  },
  {
    title: "BILLING/CHARGES",
    content: [
      "You acknowledge that by using the Services, you may be subject to charges from a third-party service provider for the services you receive (Charges). The Company, as a limited payment collection agent for a third-party service provider, will facilitate your payment of the applicable Charges after you have received services through your use of the Service. The Charges will be handled as if you had directly paid the third-party service provider. All Charges will include all applicable taxes unless otherwise specified by law. Unless otherwise stated by the Company, all Charges paid by you are final and non-refundable. ",
      "In the event of a chargeback or dispute initiated by a customer, any resulting fees, penalties, or losses incurred due to such chargeback shall be passed on to the service provider. The service provider agrees to indemnify and reimburse the Company for any costs, fees, or losses associated with chargebacks, including any administrative or processing fees imposed by the payment processor or bank.",
      "The Charges may be updated or changed at any time as per the analysis of the third-party services provider or your new requests. The updated Charges shall be communicated to you by the Company and only upon your approval of the Charges, the services will be provided by the third-party service provider. ",
      "You acknowledge and agree that all charges due to any third-party service provider must be paid in full and on time. Failure to make such payments will constitute a breach of these terms. In the event of such a breach, you shall be liable for liquidated damages equal to the value of the services provided. You expressly authorize the Company to recover the amount owed as liquidated damages through any lawful means it deems appropriate, including but not limited to deductions from any funds payable to you. Furthermore, the Company reserves the right to refer the recovery of unpaid amounts to a collection agency, and you agree that all costs associated with such collection efforts shall be borne by you.",
    ],
  },
  {
    title: "CANCELLATION AND REFUND",
    content: [
      "You have no right to request a refund or cancellation for an accepted service from the Platform. Once an order is placed (i.e., upon acceptance of the estimated cost by the User), you shall not be entitled to cancel such order or seek a refund for the Services provided.",
    ],
  },
  {
    title: "DISPUTE RESOLUTION",
    content: [
      "In case of any dispute or difference between the User and the Company regarding the interpretation of these terms, or any claims or disputes arising out of or in connection with these terms, the matter shall be referred to a sole arbitrator, appointed by mutual consent. The arbitration proceedings shall be conducted in accordance with the provisions of the Arbitration Act, 1991 (Ontario) or the applicable provincial arbitration legislation, depending on the jurisdiction of the parties involved. The courts of [province] shall have exclusive jurisdiction over the matter and/or for enforcement, as the case may be.",
    ],
  },
  {
    title: "GOVERNING LAW",
    content: [
      "These Terms and any separate agreements whereby we provide you Services, shall be governed by and construed in accordance with the laws of Canada and jurisdiction of ______.",
    ],
  },
  {
    title: "CONTACTING YOU",
    content: [
      "You agree that we may contact you through telephone, email, SMS, or any other means of communication for the purpose of obtaining feedback in relation to Platform or our Services; obtaining feedback in relation to any other Users listed on the Platform; or resolving any complaints, information, or queries by other Users regarding your critical Content.",
      "You agree to provide your fullest co-operation further to such communication by the Company.",
      "By submitting suggestions or other feedback regarding our Services/Platform, you agree that we can use and share such feedback for any purpose without any compensation to you and we are under no obligation to keep such feedback confidential.",
    ],
  },
];
