import axiosInstance, { baseURL } from "./axiosInstance";

export const auth = {
  login: `admin/login`,
  signup: `admin/signup`,
  verifyLoginOtp: `admin/verifyLoginOtp`,
  forgetPassword: `admin/requestPasswordReset`,
  verifyResetPasswordOtp: `admin/verifyResetOtp`,
  resetPasssword: `admin/resetPassword`,
  resendOtp: `admin/resendOtp`,
};

export const stats = {
  serviceStatsByWeek: "services/stats/getStatsByWeek",
  serviceStatsByMonth: "services/stats/getStatsByMonth",
  serviceStatsByYear: "services/stats/getStatsByYear",
  orderStatsByWeek: "orderStats/getStatsByWeek",
  orderStatsByMonth: "orderStats/getStatsByMonth",
  orderStatsByYear: "orderStats/getStatsByYear",
};

export const serviceRequests = {
  getAllServiceRequest: "serviceRequest",
  updateServiceRequest: "serviceRequest",
};

export const viewProfile = {
  viewProfile: "admin/viewProfile",
};

export const notification = {
  getAllNotifications: "notifications",
  deleteAllNotifications: "notifications",
  markAsAllRead: "notifications/mark-all-as-read",
};

export const services = {
  getAllServices: "services",
  updateServices: "services",
  createService: "services",
};

export const spareParts = {
  getAllSpareParts: "spareParts",
  updateSpareParts: "spareParts",
  createSparePart: "spareParts",
  deleteSparePart: "spareParts",
};

export const usedCars = {
  getAllUsedCars: "usedCars/admin",
  postUsedCars: "usedCars",
  deleteUsedCars: "usedCars",
  updateUsedCars: "usedCars",
};

export const getUsedCarData = {
  getAllUsedCars: "usedCars/admin",
  postUsedCars: "usedCars",
  deleteUsedCars: "usedCars",
  updateUsedCars: "usedCars",
};

export const customers = {
  getAllCustomers: "customers",
  getUserMe: "getUserMe",
};

export const userDetails = {
  getUserDetails: "admin/viewProfile",
  updateUserDetails: "admin/updateProfile",
};

export const offer = {
  createOffer: "offers",
  getOffers: "offers",
  updateOffers: "offers",
  deleteOffers: "offers",
};

export const orders = {
  getOrders: "orders",
  updateOrders: "orders",
  deliverOrder: "orders/deliver",
};

export const reminders = {
  getReminders: "reminder",
  updateReminders: "reminder",
  deleteReminders: "reminder",
  toggle: "reminder/toggle",
};

export const getCurrentUser = async () => {
  try {
    const response = await axiosInstance.get(viewProfile.viewProfile);

    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    return [];
  }
};

export const getServiceName = (id, services) => {
  const service = services?.find((ele) => ele?._id === id);
  return service ? service?.serviceName : "Unknown Service";
};

export function formatString(str) {
  const words = str.replace(/([A-Z])/g, " $1");
  const formatted = words
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formatted.trim();
}

export const fetchTax = async (province) => {
  try {
    const response = await axiosInstance.get(`${baseURL}/tax`, {
      params: { province },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching tax:", error);
  }
};
