export const checkPhone = (value) => {
  const phoneNumPattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  if (value.match(phoneNumPattern)) {
    return true;
  } else {
    return false;
  }
};

export const roundToTwoDecimals = (value) => {
  return parseFloat(value.toFixed(2));
};

export const calculateAmounts = ({
  actualAmount,
  additionalCharges,
  discount,
  rate,
}) => {
  const taxFee = roundToTwoDecimals(
    (actualAmount + additionalCharges - discount) * (rate / 100)
  );

  const totalAmount = roundToTwoDecimals(
    actualAmount + additionalCharges - discount + taxFee
  );

  return {
    taxFee,
    totalAmount,
  };
};

export const checkPasswordPolicy = (password) => {
  // if (password.length < 6) {
  //   return {
  //     message: "Password must be at least 6 characters long",
  //     type: "error",
  //   }; // Password must be at least 6 characters long
  // }

  let hasUppercase = {
    message: "Password must contain at least one uppercase letter",
    type: "error",
    status: false,
  };

  let hasLength = {
    message: "Password must be at least 6 characters long",
    type: "error",
  };

  let hasLowercase = {
    message: "Password must contain at least one lowercase letter",
    type: "error",
    status: false,
  };
  let hasNumber = {
    message: "Password must contain at least one number",
    type: "error",
    status: false,
  };
  let hasSpecialChar = {
    message: "Password must contain at least one special character",
    type: "error",
    status: false,
  };
  const specialChars = "@$!%*?&";

  for (let i = 0; i < password.length; i++) {
    const char = password[i];

    if (char >= "A" && char <= "Z") {
      hasUppercase = {
        message: "Password must contain at least one uppercase letter",
        type: "success",
        status: true,
      }; // Password must contain at least one uppercase letter
    } else if (char >= "a" && char <= "z") {
      hasLowercase = {
        message: "Password must contain at least one lowercase letter",
        type: "success",
        status: true,
      }; // Password must contain at least one lowercase letter
    } else if (char >= "0" && char <= "9") {
      hasNumber = {
        message: "Password must contain at least one number",
        type: "success",
        status: true,
      }; // Password must contain at least one number
    } else if (specialChars.includes(char)) {
      hasSpecialChar = {
        message: "Password must contain at least one special character",
        type: "success",
        status: true,
      }; // Password must contain at least one special character
    }

    if (password.length >= 6) {
      hasLength = {
        message: "Password must be at least 6 characters long",
        type: "sucess",
        status: true,
      };
    }

    // If all conditions are met, no need to continue the loop
  }

  // Return false if any of the conditions are not met
  return {
    hasLowercase,
    hasUppercase,
    hasNumber,
    hasSpecialChar,
    hasLength,
  };
};

export const generateLink = (id) => {
  return `https://www.youtube.com/embed/${id}?rel=0&showinfo=0&fullscreen=1`;
};
