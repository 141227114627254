import React from "react";
import { termsData } from "../../utility/constant";

const TermsAndConditions = () => {
  return (
    <div className="terms-container p-4 text-left max-w-4xl m-auto">
      {termsData?.map((ele,ind) => {
        return (
          <section key={ele.title} className="p-2 m-4">
            <h2 className={`text-xl font-semibold mb-2 ${ind ==0  &&"text-center py-2"}`}>{ele.title}</h2>

            {ele.content.map((paragraph, index) => (
              <p className="mb-2" key={index}>{paragraph}</p>
            ))}
          </section>
        );
      })}

      {/* Add additional sections here, following the same pattern */}

      <footer className="mt-4 p-2 ml-4">
        <p>
          <strong>Copyright:</strong> Axzora Inc. Proprietary.
        </p>
      </footer>
    </div>
  );
};

export default TermsAndConditions;
